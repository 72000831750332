import { Loader } from "@heathmont/moon-core";
import styles from "./style.module.css";

const AppLoader = (): React.ReactElement => {
  return (
    <div className={styles.center}>
      <Loader />
    </div>
  );
};

export default AppLoader;
