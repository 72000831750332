const { analytics } = window;

const identify = (uniqueId: string, traits: Record<string, unknown>): void => {
  if (!analytics) {
    return;
  }
  analytics.identify(uniqueId, traits);
};

const track = (eventId: string, properties?: Record<string, unknown>): void => {
  if (!analytics) {
    return;
  }
  analytics.track(eventId, properties);
};

export default { identify, track };
