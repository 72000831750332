/* eslint-disable react/prop-types */
import React, { createContext, ReactNode, useState, useEffect } from "react";
import { ThemeProvider } from "@heathmont/moon-themes";

import connectTheme from "./theme";
import connectApi from "../api/connectApi";
import InviteApi from "../api/inviteApi";
import {
  IWidgetHostMessage,
  IStepsContext,
  IInvitationStatus,
  IConnectStepState,
} from "../types";
import { IDesignTokens } from "../types/tokens";
import useConnectUrl from "../hooks/useConnectUrl";
import { RequestStatusType, REQUEST_STATUS } from "../types/status";
import { handleAxiosError } from "../api/resetClient";
import nobleAnalytics from "../utils/nobleAnalytics";

const Context = createContext<IStepsContext>({} as IStepsContext);

const { Provider } = Context;

export const ContextProvider: React.FC<{
  children: ReactNode;
  widgetState: IWidgetHostMessage;
}> = ({ children, widgetState }) => {
  const { connectUuid, token, inviteUuid, customerUuid, userId } =
    useConnectUrl();
  const [recipientName, setRecipientName] = useState<string>("");
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState<IConnectStepState[]>([]);
  const [designTokens, setDesignTokens] = useState<IDesignTokens>({});
  const [status, setStatus] = useState<RequestStatusType>(
    REQUEST_STATUS.loading
  );
  const [saveStepStatus, setSaveStepStatus] = useState<RequestStatusType>(
    REQUEST_STATUS.idle
  );
  const [invitationStatus, setInvitationStatus] = useState<IInvitationStatus>(
    IInvitationStatus.default
  );

  useEffect(() => {
    if (customerUuid) {
      nobleAnalytics.identify(customerUuid, {
        customerUuid,
        connectUuid,
        inviteUuid,
      });
    }
  }, [customerUuid, inviteUuid, connectUuid]);

  const theme = { ...connectTheme, ...designTokens };

  useEffect(() => {
    const getConnectSteps = async () => {
      try {
        setStatus(REQUEST_STATUS.loading);
        if (token && inviteUuid) {
          const invitation = await InviteApi.checkInvitation(inviteUuid, token);

          if (
            invitation &&
            invitation.data.status === IInvitationStatus.submitted
          ) {
            setRecipientName(invitation.data.recipientName);
            setInvitationStatus(IInvitationStatus.submitted);
          }
        }

        const connectSteps = await connectApi.getSteps(connectUuid);

        setSteps(connectSteps.data?.steps);
        setDesignTokens(connectSteps.data?.designTokens);
        setStatus(REQUEST_STATUS.success);
      } catch (error) {
        handleAxiosError(error, () =>
          setInvitationStatus(IInvitationStatus.invalid)
        );
        setStatus(REQUEST_STATUS.error);
      }
    };
    if (connectUuid) {
      getConnectSteps();
    } else {
      setStatus(REQUEST_STATUS.error);
    }
  }, [connectUuid, token, steps.length, inviteUuid, userId, customerUuid]);

  return (
    // @ts-expect-error theme interface
    <ThemeProvider theme={theme}>
      <Provider
        value={{
          stepsStatus: status,
          setStepsStatus: setStatus,
          steps,
          setSteps,
          stepIndex,
          setStepIndex,
          saveStepStatus,
          setSaveStepStatus,
          widgetState,
          designTokens,
          invitationStatus,
          recipientName,
        }}
      >
        {children}
      </Provider>
    </ThemeProvider>
  );
};

export default Context;
