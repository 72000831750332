import { supportColors, sharedTokens, Theme } from "@heathmont/moon-themes";

const boxShadow =
  "0px 2.4px 7.2px rgba(0, 0, 0, 0.18), 0px 12.8px 28.8px rgba(0, 0, 0, 0.22)";

const main = supportColors.popo[100];
const secondary = "#3F7EF3";

const color = {
  piccolo: {
    120: main,
    100: main,
    80: main,
  },
  hit: {
    120: secondary,
    100: secondary,
    80: secondary,
  },
  beerus: {
    100: "#E2E6E9",
  },
  goku: {
    100: "#f0f6ff",
    80: "#E2E6E9",
    40: "#c2d9ff",
    10: "#a3c6ff",
  },
  gohan: {
    100: "#ffffff",
    80: "#f7f7f7",
    40: "#e8e8e8",
    10: "#d9d9d9",
  },
  goten: {
    100: "#fff",
  },
  bulma: {
    100: "#0f161f",
  },
  trunks: {
    100: "#8697a2",
  },
};

const theme: Theme = {
  ...sharedTokens,
  // @ts-expect-error Noble doesn't exist in Theme Brand ts definition
  brand: "Noble",
  fontFamily: "'Maven Pro', sans-serif",
  fontSize: {
    body: 12,
  },
  fontWeight: {
    normal: 400,
    semibold: 500,
  },
  boxShadow,
  color: {
    text: color.bulma[100],
    background: color.goku[100],
    ...supportColors,
    ...color,
  },
  colorScheme: "light",
  radius: {
    small: 4,
    default: 8,
    largest: 8,
  },
};

export const text = {
  brand: "Noble",
  appLoader: {
    title: "Checking what needs to be done",
  },
};

export default theme;
