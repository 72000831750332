/* eslint-disable @typescript-eslint/ban-ts-comment,consistent-return */
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@heathmont/moon-themes";
import AppLoader from "./components/ui/AppLoader";
import connectTheme from "./context/theme";
import reportWebVitals from "./reportWebVitals";
import { ContextProvider } from "./context";
import { IWidgetHostMessage } from "./types";
import HostMessageDispatcher from "./utils/hostMessageDispatcher";
import "./styles/index.css";

const inIframe = process.env.REACT_APP_IFRAM_MODE === "true";
if (inIframe) {
  console.log("hi iframe");
}

const LazyApp = lazy(() => import("./App"));

const renderApp = (msg?: IWidgetHostMessage | any) => {
  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={connectTheme}>
        <Router>
          <Suspense fallback={<AppLoader />}>
            <ContextProvider widgetState={msg}>
              <LazyApp />
            </ContextProvider>
          </Suspense>
        </Router>
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
};

if (inIframe) {
  window.addEventListener("DOMContentLoaded", () => {
    HostMessageDispatcher.dispatch({
      action: "widget-loaded",
    });
    window.removeEventListener("DOMContentLoaded", () => null);
  });

  window.addEventListener("message", (event) => {
    event.preventDefault();
    if (!event.data || typeof event.data !== "string") return;
    const msg: IWidgetHostMessage = JSON.parse(event.data);
    console.log("host sent msg", event.data);
    if (msg.action === "start") {
      return renderApp(msg);
    }

    return null;
  });
} else {
  renderApp({});
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
