import { AxiosResponse } from "axios";
import resetClient, { API_BASE_URL } from "./resetClient";

export interface ICreditApplication {
  _sellerId: string;
  uuid: string;
  formUrl: string;
  formId: string;
  lastUpdated: Date;
  name: string;
  type: string;
}

interface ICommonInviteProps {
  uuid: string;
  _sellerId: string;
  _customerId: string;
  _inviteSenderId: string;
  recipientName: string;
  recipientCompanyName: string;
  recipientEmail: string;
  token: string;
}
export interface ICustomerInviteDTO extends ICommonInviteProps {
  creditApplication: ICreditApplication;
  creationDate: Date;
  status: string;
}

class InviteApi {
  checkInvitation = (
    uuid: string,
    token: string
  ): Promise<AxiosResponse<ICustomerInviteDTO>> => {
    const url = `${API_BASE_URL}/benga-invite`;
    return resetClient({
      url,
      params: {
        uuid,
        token,
      },
    });
  };
}

export default new InviteApi();
