export const STEP_STATUS = {
  pending: "pending",
  done: "done",
  failed: "failed",
} as const;

export type StepStatusType = typeof STEP_STATUS[keyof typeof STEP_STATUS];

export const REQUEST_STATUS = {
  idle: "idle",
  loading: "loading",
  error: "error",
  success: "success",
} as const;

export type RequestStatusType =
  typeof REQUEST_STATUS[keyof typeof REQUEST_STATUS];
