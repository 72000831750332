import { Dispatch, SetStateAction } from "react";
import { IConnectStepResponse, StepProps } from "./steps";
import { IDesignTokens } from "./tokens";
import { RequestStatusType, StepStatusType } from "./status";

export enum IInvitationStatus {
  submitted = "Submitted",
  alive = "alive",
  default = "default",
  invalid = "invalid",
}

export enum IStepStatus {
  Pending = "pending",
  Done = "done",
  Failed = "failed",
}

export enum IRequestStatus {
  Idle = "idle",
  Loading = "loading",
  Error = "error",
  Success = "success",
  Finished = "Finished",
  Invalid = "Invalid",
}
export interface IStepResponse {
  type: string;
  title: string;
  status: IStepStatus;
  uuid: string;
  description?: string;
  props?: Record<string, unknown>;
}

export type IButtonProps = null | { label?: string; canBeSkipped?: boolean };

export interface IConnectStepState<T = string, Props = StepProps | undefined>
  extends IConnectStepResponse<T, Props> {
  payload?: Record<string, unknown>;
}

export interface IPlaidPayload {
  metadata?: Record<string, unknown>;
  publicToken?: string;
}
export interface Error {
  message: string;
}
export interface IWidgetHostMessage {
  action: string;
  payload?: Record<string, unknown>;
}

export interface IStepsContext {
  stepIndex: number;
  setStepIndex: Dispatch<SetStateAction<number>>;
  steps: IConnectStepState[];
  setSteps: Dispatch<SetStateAction<IConnectStepState[]>>;
  stepsStatus: RequestStatusType;
  setStepsStatus: Dispatch<SetStateAction<RequestStatusType>>;
  saveStepStatus: RequestStatusType;
  setSaveStepStatus: Dispatch<SetStateAction<RequestStatusType>>;
  widgetState: IWidgetHostMessage;
  designTokens: IDesignTokens;
  invitationStatus: IInvitationStatus;
  recipientName: string;
}

export interface IStepComponentProps<T = string, Props = StepProps> {
  step: IConnectStepState<T, Props>;
  updateStep: (x: Partial<IConnectStepState>) => void;
  saveStep: (step: Partial<IConnectStepState>) => Promise<StepStatusType>;
  saveStepStatus: RequestStatusType;
}
