import { IWidgetHostMessage } from "../types";

class HostMessageDispatcher {
  static dispatch(msg: IWidgetHostMessage): void {
    window.parent.postMessage(
      JSON.stringify({
        type: "noble-msg",
        action: `noble::${msg.action}`,
        payload: msg.payload,
      }),
      "*"
    );
  }
}

export default HostMessageDispatcher;
