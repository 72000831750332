import { useLocation, useHistory } from "react-router-dom";

interface IUseConnectUrl {
  userId: string | null;
  customerUuid: string | null;
  connectUuid: string;
  updateCustomerUuidPathParam: (customerUuid: string) => void;
  token: string;
  inviteUuid: string;
}

const useConnectUrl = (): IUseConnectUrl => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const history = useHistory();

  const connectUuid = location.pathname.split("/")[1];
  const userId = query.get("userId");
  const token = location.pathname.split("/")[2];
  const inviteUuid = location.pathname.split("/")[3];
  const customerUuid = query.get("customer") || location.pathname.split("/")[4];

  const updateCustomerUuidPathParam = (newCustomerUuid: string) => {
    const params = new URLSearchParams({ customer: newCustomerUuid });
    history.replace({ pathname: location.pathname, search: params.toString() });
  };

  return {
    userId,
    customerUuid,
    connectUuid,
    updateCustomerUuidPathParam,
    token,
    inviteUuid,
  };
};

export default useConnectUrl;
