import { AxiosResponse } from "axios";
import resetClient, { API_BASE_URL } from "./resetClient";
import { IConnectStepState } from "../types";
import { IDesignTokens } from "../types/tokens";
import { IConnectStepResponse } from "../types/steps";
import { StepStatusType } from "../types/status";

interface ConnectResponse {
  steps: IConnectStepResponse[];
  designTokens: IDesignTokens;
}
interface IConnectStepSaveResponse {
  status: StepStatusType;
  customerUuid?: string;
}

class ConnectApi {
  getSteps = (connectUuid: string): Promise<AxiosResponse<ConnectResponse>> => {
    const url = `${API_BASE_URL}/connect/${connectUuid}`;
    return resetClient({
      url,
    });
  };

  saveStep = (
    connectUuid: string,
    token: string,
    body: {
      step: Partial<IConnectStepState>;
      userId?: string;
      customerUuid?: string;
      inviteUuid?: string;
    }
  ): Promise<AxiosResponse<IConnectStepSaveResponse>> => {
    const url = `${API_BASE_URL}/connect/${connectUuid}`;
    return resetClient({
      method: "POST",
      body: {
        ...body,
        token,
      },
      url,
    });
  };
}

export default new ConnectApi();
