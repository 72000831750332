import axios, { AxiosError, AxiosPromise, Method, ResponseType } from "axios";
import config from "../config";

interface AuthHeader {
  authorization: string;
}

interface FetchOptions {
  url: string;
  method?: Method;
  body?: any;
  headers?: any;
  params?: any;
  responseType?: ResponseType;
}

export const API_BASE_URL = config.API_URL;

export const getAuthHeader = (token: string): AuthHeader => ({
  authorization: `bearer ${token}`,
});

export default (options: FetchOptions): AxiosPromise => {
  const {
    method = "get",
    url,
    body,
    headers,
    params = {},
    responseType,
  } = options;

  return axios({
    method,
    url,
    data: body,
    headers,
    params,
    responseType,
  });
};

// Idea behind is that we can add axios handler for error type/s
export const handleAxiosError = (
  error: unknown,
  callback: () => void
): void => {
  if (axios.isAxiosError(error)) {
    if ((error as AxiosError).response?.status === 400) {
      // currently only check
      callback();
    }
  }
};
