type NobleEnv = {
  API_URL: string;
  CLIENT_URL: string;
};

const staging: NobleEnv = {
  API_URL: "https://api.staging.benoble.io",
  CLIENT_URL: "https://staging--noble-connect.netlify.app/",
};
const test: NobleEnv = {
  API_URL: "https://api.test.benoble.io",
  CLIENT_URL: "https://test--noble-connect.netlify.app/",
};
const dev: NobleEnv = {
  API_URL: "http://localhost:3300",
  CLIENT_URL: "http://localhost:3000",
};

const prod: NobleEnv = {
  API_URL: "https://api.benoble.io",
  CLIENT_URL: "https://connect.benoble.io",
};

const reactEnvironment = process.env.REACT_APP_ENVIRONMENT;
export const DATADOG_CLIENT_TOKEN =
  process.env.REACT_APP_DATADOG_CLIENT_TOKEN || null;

let localConf = dev;

if (reactEnvironment === "test") {
  localConf = test;
} else if (reactEnvironment === "staging") {
  localConf = staging;
} else if (reactEnvironment === "production") {
  localConf = prod;
}
const config = localConf;

export default config;
